import { useEffect, useContext } from 'react'
import { useImmer } from "use-immer"
import { Modal, Button, message, Tooltip, Space, Input, Popconfirm } from "antd"
import { CopyOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { copyToClipboard } from '../Utils'
import { DEAL_TYPES } from "../Constants"
import AntSelect from "./AntSelect"

import Axios from "axios"
import api from '../AxiosInstance'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'

import '../App.css'


function GenericWarningModal(props) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [brandModelState, setBrandModelState] = useImmer(
        {liste:Array(3).fill({real_brand:"", real_model:""}), dealType:{data:DEAL_TYPES, selected:"-None-"}, requestCount:0}
    )
    const [partNumberState, setPartNumberState] = useImmer({mapping:[], requestCount:0}) // {"pn":pn, "real_brand":"", "real_model":"", }

    useEffect(() => {
        if (brandModelState.requestCount === 0) return

        const ourRequest = Axios.CancelToken.source()
        async function saveBrandModels() {
            try {
                const dataToSend = {
                    deal_type: brandModelState.dealType.selected,
                    brand_models: brandModelState.liste,
                    message_id: props.state.msgId,
                }
                const response = await api.post(
                    'update_gt_brand_models/',
                    dataToSend,
                    appState.token.post_config,
                    { cancelToken: ourRequest.token }
                )
                appDispatch({
                    type: "success",
                    data: "Brand models updated"
                })
                props.updateRecordData(props.state.msgId, "deal_type_gt", dataToSend.deal_type)
                props.updateRecordData(props.state.msgId, "bm_gt_list", dataToSend.brand_models)
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to update brand models!"
                })
            }
        }
        saveBrandModels()
    }, [brandModelState.requestCount])

    useEffect(() => {
        if (partNumberState.requestCount === 0) return

        const ourRequest = Axios.CancelToken.source()
        async function savePartNumbers() {
            try {
                const response = await api.post(
                    'update_gt_part_numbers_and_mappings/',
                    {'mapping':partNumberState.mapping, 'message_id':props.state.msgId},
                    appState.token.post_config,
                    { cancelToken: ourRequest.token }
                )
                appDispatch({
                    type: "success",
                    data: "Part numbers and mappings updated"
                })
                props.updateRecordData(props.state.msgId, "pn_bm_list", partNumberState.mapping)
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to update part numbers and mappings!"
                })
            }
        }
        savePartNumbers()
    }, [partNumberState.requestCount])

    useEffect(() => {
        if (!props.state.showNLPResult) return
        const tempArr = props.state.bmGTList.length === 0 ? Array(3).fill({real_brand:"", real_model:""}) : props.state.bmGTList
        setBrandModelState(draft => {
            draft.liste = tempArr
            draft.dealType.selected = props.state.dealTypeGT
        })
        setPartNumberState(draft => {
            draft.mapping = props.state.pnBMList
        })
    }, [props.state.msgId])

    const [messageApi, contextHolder] = message.useMessage()

    const success = (msg) => {
        messageApi.open({
            type: 'success',
            content: msg,
            //className: 'no-trans',
            duration: '1.',
        })
    }

    function closeModal() {
        props.setState(draft => {
            draft.modal.isOpen = false
        })
        props.setDataSource(draft => {
            draft.isLoading = false
        })
    }

    function handleOk() {
        closeModal()
    }

    function handleCancel() {
        closeModal()
    }

    function footer() {
        let footerList = []
        if (props.displayCopyButton) {
            footerList.push(
                <Tooltip key={"copy_tooltip"} title={"Copy content"} placement="bottom">
                    <Button key={"copy"} size='small' icon={<CopyOutlined />}
                        onClick={(e) => {
                            //copyToClipboard(cropText(props.record.message.cleaned_content, 100))
                            copyToClipboard(props.state.title)
                            success("Copied!")
                        }} />
                </Tooltip>
            )
        }
        if (props.notDisplayOkButton)
            return footerList
        footerList.push(
            <Button key="submit" type="primary" onClick={handleOk}>
                OK
            </Button>
        )
        return footerList
    }

    function modalStyle() {
        if (props.state.showNLPResult) {
            return (
                {
                    position: 'fixed',
                    top: '30%',
                    left: '0',
                    transform: 'translate(0, -50%)',
                    marginLeft: 250,
                    marginTop: 100,
                }
            )
        } else {
            return ""
        }
    }

    function onDealTypeChange(value) {
        setBrandModelState(draft => {
            draft.dealType.selected = value
        })
    }

    function onBrandChange(value, index) {
        setBrandModelState(draft => {
            draft.liste[index].real_brand = value
        })
    }

    function onModelChange(value, index) {
        setBrandModelState(draft => {
            draft.liste[index].real_model = value
        })
    }

    function addNewRowBM() {
        setBrandModelState(draft => {
            draft.liste.push({real_brand:"", real_model:""})
        })
    }

    function deleteRowBM(index) {
        setBrandModelState(draft => {
            draft.liste.splice(index, 1)
        })
    }

    function removeBrandModels() {
        setBrandModelState(draft => {
            draft.liste = Array(3).fill({real_brand:"", real_model:""})
        })
    }

    function onPartNumberChange(value, index) {
        setPartNumberState(draft => {
            draft.mapping[index].pn = value
        })
    }

    function onPNBrandChange(value, index) {
        setPartNumberState(draft => {
            draft.mapping[index].real_brand = value
        })
    }

    function onPNModelChange(value, index) {
        setPartNumberState(draft => {
            draft.mapping[index].real_model = value
        })
    }

    function addNewRowPN() {
        setPartNumberState(draft => {
            draft.mapping.push({pn: "", real_brand:"", real_model:""})
        })
    }

    function deleteRowPN(index) {
        setPartNumberState(draft => {
            draft.mapping.splice(index, 1)
        })
    }

    function removePNBrandModelMappings() {
        setPartNumberState(draft => {
            draft.mapping = []
        })
    }

    return (
        <Modal
            centered={!props.state.showNLPResult}
            title=""
            open={props.state.modal.isOpen}
            onCancel={handleCancel}
            footer={footer()}
            style={modalStyle()}
            >
                {contextHolder}
                <Space direction="horizontal">
                    <div style={{minWidth:"450px", maxHeight:"540px", overflowY:"auto"}}>
                        <span className='withprewrap'>
                            {props.state.title}
                        </span>
                    </div>
                    {props.state.showNLPResult ? 
                        <Space direction="horizontal">
                            <div style={{
                                backgroundColor:"white", padding:16, marginLeft:40,
                                borderRadius:10, width:"300px", height:"400px", overflowY:"auto"
                            }}>
                                <div style={{paddingBottom:"10px"}}><strong>Extracted Brand Models</strong></div>
                                <div style={{paddingBottom:"10px"}}>
                                    <strong>Deal Type: </strong>
                                    {props.state.dealType}
                                </div>
                                {props.state.bmList.map((item, idx) => {
                                    return <div key={idx} /*style={{whiteSpace:'nowrap'}}*/>{`> ${item}`}</div>
                                })}
                            </div>
                            <div style={{
                                backgroundColor:"white", padding:16, marginLeft:-6,
                                borderRadius:10, width:"360px", height:"400px",
                            }}>
                                <Space direction="vertical">
                                    <div><strong>Ground Truth Brand Models</strong></div>
                                    <div>
                                        <strong>Deal Type: </strong>
                                        <AntSelect options={brandModelState.dealType.data} selected={brandModelState.dealType.selected}
                                            width={115} onChange={onDealTypeChange} size="small"/>
                                    </div>
                                    <Space direction="vertical" size="small">
                                        <div style={{height:"290px", overflowY:"auto", }}>
                                            {brandModelState.liste.map((item, idx) => {
                                                return (
                                                    <Space key={idx} direction="horizontal" style={{paddingBottom:"4px"}}>
                                                        <Input placeholder="Brand" style={{width:"130px"}} size="small"
                                                            value={item.real_brand} onChange={(e) => onBrandChange(e.target.value, idx)}/>
                                                        <Input placeholder="Model" style={{width:"170px"}} size="small"
                                                            value={item.real_model} onChange={(e) => onModelChange(e.target.value, idx)}/>
                                                        {//brandModelState.liste.length === 1 ? "" :
                                                        <Tooltip title="Delete Row">
                                                            <Popconfirm
                                                                title="Are you sure?"
                                                                //description=""
                                                                onConfirm={(e) => { deleteRowBM(idx) }}
                                                                //onCancel={cancel}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <MinusCircleOutlined style={{fontSize:"18px", cursor:"pointer", color:"red"}} />
                                                            </Popconfirm>
                                                        </Tooltip>}
                                                    </Space>
                                                )
                                            })}
                                        </div>
                                        <Space>
                                            <Button type="primary" onClick={() => addNewRowBM()}>
                                                Add Row
                                            </Button>
                                            <Popconfirm
                                                title="Are you sure?"
                                                //description=""
                                                onConfirm={(e) => { removeBrandModels() }}
                                                //onCancel={cancel}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button type="primary">
                                                    Remove All
                                                </Button>
                                            </Popconfirm>
                                            <Button type="primary" onClick={() => setBrandModelState(draft=>{draft.requestCount++})}>
                                                SAVE
                                            </Button>
                                        </Space>
                                    </Space>
                                </Space>
                            </div>
                            <div style={{
                                backgroundColor:"white", padding:16, marginLeft:-6,
                                borderRadius:10, width:"450px", height:"400px", overflowY:"auto"
                            }}>
                                <Space direction="vertical">
                                    <div><strong>PN - Brand Model Mappings</strong></div>
                                    <Space direction="vertical" size="small">
                                        <div style={{height:"322px", overflowY:"auto", }}>
                                            {partNumberState.mapping.map((item, idx) => {
                                                return (
                                                    <Space key={idx} direction="horizontal" style={{paddingBottom:"4px"}}>
                                                        <Input placeholder="PN" style={{width:"115px"}} size="small"
                                                            value={item.pn} onChange={(e) => onPartNumberChange(e.target.value, idx)}/>
                                                        <Input placeholder="Brand" style={{width:"115px"}} size="small"
                                                            value={item.real_brand} onChange={(e) => onPNBrandChange(e.target.value, idx)}/>
                                                        <Input placeholder="Model" style={{width:"145px"}} size="small"
                                                            value={item.real_model} onChange={(e) => onPNModelChange(e.target.value, idx)}/>
                                                        {//partNumberState.mapping.length === 1 ? "" :
                                                        <Tooltip title="Delete Row">
                                                            <Popconfirm
                                                                title="Are you sure?"
                                                                //description=""
                                                                onConfirm={(e) => { deleteRowPN(idx) }}
                                                                //onCancel={cancel}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <MinusCircleOutlined style={{fontSize:"18px", cursor:"pointer", color:"red"}} />
                                                            </Popconfirm>
                                                        </Tooltip>}
                                                    </Space>
                                                )
                                            })}
                                        </div>
                                        <Space>
                                            <Button type="primary" onClick={() => addNewRowPN()}>
                                                Add Row
                                            </Button>
                                            <Popconfirm
                                                title="Are you sure?"
                                                //description=""
                                                onConfirm={(e) => { removePNBrandModelMappings() }}
                                                //onCancel={cancel}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button type="primary">
                                                    Remove All
                                                </Button>
                                            </Popconfirm>
                                            <Button type="primary" onClick={() => setPartNumberState(draft=>{draft.requestCount++})}
                                                disabled={partNumberState.mapping.length===0}>
                                                SAVE
                                            </Button>
                                        </Space>
                                    </Space>
                                </Space>
                            </div>
                        </Space>
                    : ""}
                </Space>
        </Modal>
    )
}

export default GenericWarningModal
